<template>
  <div>
    <client-only>
      <FloorTab
        v-if="goodsReady && topContentReady"
        class="detail-private"
      />
    </client-only>
    <div
      id="detail-view"
      class="mgds-goodsd-bg j-mgds-goodsd product-intro"
      v-bind="detail_page_bind_data"
      @touchstart="fid"
    >
      <template v-if="isClientRender">
        <div>
          <div class="main-pic__container">
            <MainPicture
              :topContentReady="topContentReady"
              @onMounted="onMainPictureMounted"
            />
            <div
              v-if="show_try_on_ar_content"
              class="goods-detail-top__arContent"
            >
              <GeeneeArBox
                :skuCode="skuCode"
                @close="onArboxClose"
                @careBearsStarted="onCareBearsStarted"
                @careBearsFinished="onCareBearsFinished"
              />
            </div>
          </div>

          <PlaceholderGoodsInfo
            v-if="showPlaceholder"
            class="main-placeholder"
          />
          <ClientOnly>
            <template v-if="isMainPictureMounted">
              <TopContent
                :topContentReady="topContentReady"
                :isClientRender="true"
              />
              <div v-if="topContentReady">
                <MiddleContent />
                <BottomContent />
              </div>
            </template>
          </ClientOnly>
        </div>
      </template>
      <div v-else>
        <TopContent :topContentReady="topContentReady" />
        <div v-if="topContentReady">
          <MiddleContent />
          <BottomContent />
        </div>
      </div>
    </div>
    <ClientOnly>
      <!-- 删除div会导致在站外商详为true时SSR二次渲染 -->
      <div>
        <BffVimeoPlayer v-if="show_vimeo_player" />
        <BffNativePlayer v-if="show_native_player" />
      </div>
    </ClientOnly>
    <ClientOnly>
      <!-- 加车按钮 -->
      <AddBagV2 v-if="topContentReady" />
      <AddBagPlaceholder
        v-else
        :default-text="language_v2.SHEIN_KEY_PWA_15019"
        :high-light="true"
      />
    </ClientOnly>
    <ProductDetailAgeLimitV2 v-if="show_age_limit" />

    <!-- rootLoading的情况下不显示该loading -->
    <ClientOnly>
      <s-loading
        v-if="!rootSLoading.show"
        class="detail-page__loading"
        :show="loading"
        type="curpage"
      />
    </ClientOnly>
  </div>
</template>

<script>
/* globals__webpack_public_path__ */
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import TopContent from 'public/src/pages/goods_detail_v2/innerComponents/top/index.vue'
import AddBagV2 from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/index.vue'
import AddBagPlaceholder from 'public/src/pages/goods_detail_v2/innerComponents/top/AddToCartBar/Placeholder.vue'

import { handleHash, hashUnBind } from 'public/src/pages/goods_detail_v2/utils/hash.js'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { refreshAnalyisParams, setAnalyisParams } from 'public/src/pages/goods_detail_v2/analysis/index.js'
import GBDetailAnalysisEvent from 'public/src/pages/goods_detail_v2/analysis/ga.js'
import { getQueryString, parseQueryString } from '@shein/common-function'
import { exposeMixin } from '@shein-aidc/basis-sa-event-center'
import { setFeedbackRecInSession } from 'public/src/pages/product_list_v2/js/utils.js'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { changeDeepLinkElement } from 'public/src/pages/components/journey-branch/deeplinkElement.js'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import switchColorCache from 'public/src/pages/goods_detail_v2/utils/switchColorCache.js'
import pageContextCallBack from 'public/src/pages/goods_detail_v2/utils/pageContextCallBack.js'
import recommendExposureListInstance from 'public/src/pages/goods_detail_v2/utils/recommendExposureList.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import PlaceholderGoodsInfo from 'public/src/pages/goods_detail_v2/innerComponents/top/PlaceholderGoodsInfo/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import MainPicture from 'public/src/pages/goods_detail_v2/innerComponents/top/MainPicture/index.vue'
// mixin
import { getDetailSEOMixins } from 'public/src/pages/goods_detail_v2/utils/seo.js'
import { fixIOSScrollIssue } from 'public/src/js/utils/ios_scroll_fix.js'

const GeeneeArBox = () =>
  import(
    /* webpackChunkName: "GeeneeArBox" */ 'public/src/pages/geenee_ar_box/container.vue'
  )

const { PUBLIC_CDN } = gbCommonInfo
__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

if (typeof window !== 'undefined' && !window.SaPageInfo) window.SaPageInfo = {}

export default {
  name: 'GoodsDetail',
  components: {
    GeeneeArBox,
    ClientOnly,
    MainPicture,
    PlaceholderGoodsInfo,
    TopContent,
    AddBagV2,
    AddBagPlaceholder,
    MiddleContent: prefetchResource.importAsyncComponent({
      chunkName: 'middlecontent',
      componentFactory: () => import(/* webpackChunkName: "middlecontent" */ 'public/src/pages/goods_detail_v2/innerComponents/middle/index.vue')
    }),
    BottomContent: prefetchResource.importAsyncComponent({
      chunkName: 'bottomcontent',
      componentFactory: () => import(/* webpackChunkName: "bottomcontent" */ 'public/src/pages/goods_detail_v2/innerComponents/bottom/content.vue')
    }),
    FloorTab: () => import(/* webpackChunkName: "floorTab" */ 'public/src/pages/goods_detail/components/FloorTab/index.vue'),
    ProductDetailAgeLimitV2: () =>
      import(/* webpackChunkName: "ProductDetailAgeLimitV2" */ 'public/src/pages/goods_detail_v2/innerComponents/drawer/ProductDetailAgeLimit.vue'),
    BffVimeoPlayer: () => import(/* webpackChunkName: "BFF_VimeoPlayer" */ 'public/src/pages/goods_detail_v2/innerComponents/common/VimeoPlayer.vue'),
    BffNativePlayer: () => import(/* webpackChunkName: "BFF_NativePlayer" */ 'public/src/pages/goods_detail_v2/innerComponents/common/NativePlayer.vue'),
  },
  mixins: [exposeMixin, getDetailSEOMixins()],
  provide() {
    return {
      cutImg: this.cutImg
    }
  },
  asyncData({ store, context, from = {} }) {
    const isSsrPage = !from.name
    store.commit('newProductDetail/getCacheFsAbt', context)
    const isBffFs = !!context?.IS_BFF_FS
    // TODO: SSR需要传一下fsAbt,要测一下
    store.commit('productDetail/update_cache_screen_fs_abt', context?.modules?.pageInfo?.fsAbt)
    store.commit('newProductDetail/common/setLocals', context.locals)
    // ssr或者客户端数据直出直接赋值
    if (isSsrPage && context && context.ssrPageType === 'productDetail') {
      // 新版数据
      if (context.modules && isBffFs) {
        // 走bff处理过程
        store.commit('productDetail/init_with_ssr', context)
      } else if (context.modules && context.modules.productInfo) {
        store.commit('productDetail/update_main_bff_apollo', { apolloConfig: context?.BFF_APOLLO_CONFIG })
        store.commit('newProductDetail/initCold', context)
        store.commit('newProductDetail/common/updateGoodsReady', true)
        if(context.modules.initSkuInfo) {
          store.commit('newProductDetail/common/updateSkuInfo', context.modules.initSkuInfo)
        }
        if (typeof window !== 'undefined') {
          if (!context?.modules?.productInfo?.detail?.goods_id) return
          switchColorCache.setBuffer(context.modules.productInfo.detail.goods_id, 'cold', context)
        }
      
      }
    }else if(typeof window !== 'undefined') {
      store.commit('productDetail/update_main_bff_apollo')
    }
    return Promise.resolve(context)
  },
  data() {
    return {
      isMainPictureMounted: false,
      reviewList: '',
      breadcrumbList: null,
      schemaSEO: null,
      hashChangeCallback: null,
      isAfterSsr: false,
      initOCPBillNo: '',
    }
  },
  computed: {
    ...mapGetters('newProductDetail/MiddleAttr', ['pageComponents']),
    ...mapGetters('newProductDetail/common', [
      'skuCode',
      'allDataReady',
      'showPlaceholderGoodsInfo',
      'mallCode',
      'defaultMallCode',
      'detail',
    ]),
    ...mapGetters('newProductDetail', ['isPerformance']),
    ...mapState('newProductDetail/common', [
      'locals',
      'goodsReady',
      'topContentReady',
      'fromSwitchColor',
      'addToBagForm',
      'skuInfo',
      'externalSizeInfoIndex',
      'loading',
      'attribute',
      'mainAttribute',
      'oneClickPayState',
      'exposeGoods',
      'initExposeStatus',
      'asyncScrollStatus',
      'isLoginChange'
    ]),
    ...mapGetters('newProductDetail/Carousels', ['showVideoEntry']),
    ...mapState('newProductDetail/SizeBox', ['switchMallCode']),
    ...mapState(['rootSLoading']),

    // NEW BFF
    ...mapState('productDetail', ['MAIN_BFF_APOLLO', 'isClientRender']),
    ...mapState('productDetail/common', ['show_try_on_ar_content']),
    ...mapGetters('productDetail/Main', ['show_vimeo_player', 'show_native_player']),
    ...mapGetters('productDetail/common', ['size_attr_info', 'show_age_limit', 'goods_id', 'goods_sn', 'language_v2', 'fs_abt']),
    ...mapGetters('productDetail/SizeAttrFold', ['show_sale_attr_fold_entry']),
    ...mapGetters('productDetail/DetailSignBoard', ['store_detail']),
    ...mapGetters('productDetail/common', ['is_spu_sold_out', 'is_some_sku_sold_out']),
    ...mapGetters('productDetail/price', ['recommend_retail_price', 'unit_price_info']),
    ...mapGetters('productDetail/MainPicture', ['carouse_data', 'locate_labels_data', 'property_pic_data_base']),
    ...mapGetters('productDetail', [
      'product_detail_abt_info',
      'detail_page_bind_data',
      'price_info'
    ]),
    ...mapGetters('productDetail/Description', ['trends_info']),

    showPlaceholder() {
      if (typeof window === 'undefined') return true
      if (this.fromSwitchColor) return false
      return (!this.showPlaceholderGoodsInfo && !this.goodsReady) || !this.isMainPictureMounted
    },
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window !== 'undefined') {
      window.requestAnimationFrame(() => {
        const detailElements = document?.querySelectorAll('.j-spa-goback, .j-dt-go-category-list-detail')
        if(detailElements){
          detailElements.forEach(el => el.style.display = 'block')
        }
        const msgElements = document?.querySelectorAll('.j-dt-go-category-index, .j-header-msg')
        if (msgElements) {
          msgElements.forEach(element => element.style.display = 'none')
        }
      })
      if (window.resourceSdkCase) {
        window.resourceSdkCase.updateCutScene('goods_detail')
      }
      // to.meta.ignoreScrollBehavior = true
      return next(vm => {
        vm.setQueryString()
      })
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    this.initOutfitStatus()
    this.changeRootSLoadingStatus({
      // 当跳转去其他页面，关闭loading， WEBS-35869
      show: false,
      curPageScrollFix: false
    })
    gbCommonInfo.robotStoreData = ''
    this.setFeedbackRec()
    if (typeof window !== 'undefined' && window.resourceSdkCase) {
      window.resourceSdkCase.updateCutScene('')
    }
    // 离开页面清空缓存
    switchColorCache.clearAll()
    pageContextCallBack.clear('changeColorReady')

    // 关闭大图弹窗
    this.updateShowReviewImgbox(false)
    this.updateExternalSkuCode('')
    setTimeout(() => { // 延时清空避免转场重复渲染给其他用户造成影响
      this.updateCacheScreenData(null)
      this.updateRecommendGoodsIdMap(Object.create(null))
    }, 200)
    next()
  },
  beforeRouteUpdate(to, from, next) {
    const isSameGoods = to.params[1] == from.params[1]
    if (isSameGoods) return // 同一个商品不执行，防止hash改动导致的滚动问题

    // 切换颜色或者点击推荐
    if (to.params[1] != from.params[1]) {
      sa('send', 'pageLeave', { end_time: new Date().getTime() })
      // after fromSwitchColor updated
      Vue.nextTick(() => {
        switchColorCache.flushBuffer()
        this.clearImgBfCache()
        recommendExposureListInstance.reset()
        if (this.fromSwitchColor) {
          // 切换色块也清除缓存数据
          this.updateCacheScreenData(null)
          // 重置状态后切颜色的状态要保留
          this.asyncScrollStatus &&
            this.changeRootSLoadingStatus({
              show: true,
              curPageScrollFix: false
            })
        } else {
          this.updatedDelayDestroy(true)
          // 非切换颜色清空缓存
          switchColorCache.clearAll()
          this.updateGoodsReady(false)
          this.updateRealTimeReady(false)
          // BFF
          this.update_second_info_ready(false)
          this.update_third_info_ready(false)
          this.clearLastGoodsInfo() // 推荐切换必须清除上一个商品的某些信息保证页面数据变化时机正确
          Vue.nextTick(() => {
            this.changeRootSLoadingStatus({
              show: true,
              curPageScrollFix: false
            })
          })
        }
      })
      // to.meta.ignoreScrollBehavior = false
    }
    // to.meta.ignoreScrollBehavior = true
    // to.meta.afterEachCallbak = () => {
    //     this.detailPageScrollToTop()
    // }

    next()
  },
  watch: {
    $route(newVal, oldVal) {
      // 切颜色或者推荐跳转
      if (newVal.params[1] != oldVal.params[1]) {

        // 新版数据流
        this.updateGoodsReady(false)
        this.updateRealTimeReady(false)
        this.updateViewPromotionStatus({
          status: false,
          isQuickAdd: false
        })
        this.initOutfitStatus()
        this.update_pv_clicked_rank_id('') // skc纬度的榜单过滤id重置
        this.update_protect_rank_id('clear') // skc纬度的榜单过滤id重置
        this.close_view_comment_pop() // 跳转别的商详页强制关闭评论弹窗，防止部分情况评论弹窗未关闭问题

        if (!this.fromSwitchColor) {
          this.setQueryString()
        }
        let cacheExternalSizeInfoIndex = {}
        let cacheSaleAttrsFoldAb = ''
        if (this.fromSwitchColor) {
          cacheExternalSizeInfoIndex = JSON.parse(JSON.stringify(this.externalSizeInfoIndex || {}))
          cacheSaleAttrsFoldAb = this.show_sale_attr_fold_entry
        }
        this.bfCache()
        this.updateLoading(true)
        this.requestDetailInfo({
          goodsId: newVal.params[1],
          mallCode: newVal.query?.mallCode,
          realTimeConfig: {
            estimatedPriceInfo: true
          }
        }).finally(() => {
          this.updateLoading(false)
          if (this.fromSwitchColor && cacheSaleAttrsFoldAb != this.show_sale_attr_fold_entry) {
            this.sizeCompAsync(cacheExternalSizeInfoIndex)
          }
          Vue.nextTick(() => {
            const isIos = typeof navigator !== 'undefined' && /iPhone|iPad|iPod/i.test(navigator.userAgent) || false
            // 修复 ios设备的chrome，多次从商详推荐列表位进入商详，滚动到顶部的问题
            if (isIos && typeof document?.documentElement?.scrollTop !== 'undefined' && document.documentElement.scrollTop !== 0) { // ios下切换商详有可能无法滚动到顶部
              document.documentElement.scrollTop = 0
            }
            // 第一次滚动，触发重新渲染，让按钮位置正常
            fixIOSScrollIssue(0)
            // 第二次滚动，触发重新渲染，让按钮触控位置也正常
            fixIOSScrollIssue(0)
          })
        })
      }
    },
    skuInfo: {
      deep: true,
      handler(val) {
        if (JSON.stringify(val) !== '{}') {
          this.getRealTimeSchemaSEO()
        }
      }
    },
    switchMallCode(newVal) {
      if (newVal) {
        this.updateRealTimeData(this.goods_id)
        this.handle_recent_view()
      }
    },
    goodsReady: {
      handler: async function (val) {
        this.initDetailState(val)
        if (val) {
          this.goodsReadyCallBack()
          await this.$nextTick()
          this.getRealTimeSchemaSEO()
        } else {
          this.initNewProductDetail()
          this.init_new_product_detail()
        }
      },
      immediate: true
    },
    allDataReady() {
      // 静态 + 动态数据准备就绪的回调
      if (this.allDataReady) {
        this.allDataReadyCallBack()
        SaPageInfo.page_param.is_include_picturelabel = this.locate_labels_data?.length ? 1 : 0
        // 是否含有属性图
        SaPageInfo.page_param.include_description_picture = this.property_pic_data_base?.data ? '1' : '0'
      } 
    },
    store_detail: {
      handler(val) {
        if (typeof window === 'undefined') return
        const { store_code, business_model } = this.store_detail || {}
        if (!store_code) return
        gbCommonInfo.robotStoreData = { storeCode: store_code, business_model }
        const { storeCode } = val || {}
        if (!storeCode) return
        gbCommonInfo.robotStoreData = val
      },
      immediate: true
    },
    isLoginChange() {
      this.$store.commit('newProductDetail/updateIsLogin', true)
    },
  },
  created() {
    // 新版数据
    if (typeof window !== 'undefined') {
      const { goods_id } = this.$store.state.productDetail.coldModules.productInfo || {}
      // xhr渲染 包括 列表商详转场 切换skc
      const isAfterSsr = goods_id && goods_id === this.$route.params[1]
      const isXhr = !goods_id || !isAfterSsr

      if (isAfterSsr) {
        this.isAfterSsr = true
        this.updateRealTimeData(goods_id, isAfterSsr)
        // Sa init处理
        this.initSa(goods_id)
        this.recordPageView()
        this.reportInit()
        this.dealOther()
      } else if (isXhr) {
        this.init_hybrid_modules_with_client() // 客户端更新hybrid数据

        this.updateGoodsReady(false)
        this.updateRealTimeReady(false)
        this.getDetailInfo()
      }

      // 列表页跳转
      this.bfCache()
      if (typeof window !== 'undefined') {
        appEventCenter.$on('detailPageOnload', this.detailPageOnloadEventCallback)
        appEventCenter.$on('detailFetchMoreInfo', this.detailFetchMoreInfoEventCallback)
        appEventCenter.$on('detailLoginSucCb', this.loginModalSucCb)
      }
      return
    }
  },
  mounted() {
    const { hashChangeCallback } = handleHash.call(this) // this传入   handleHash里面会用到当前vue实例里面的东西 上下文特别不明确注意！！！
    location.hash === '#comment-all' && hashChangeCallback() // 如有评论hash则要打开评论弹窗
    this.hashChangeCallback = hashChangeCallback
    window.vBus && window.vBus.$on('addToBagSuccess', this.addToBagSuccess)

    this.initOCPBillNo = parseQueryString(location.search)?.billno ?? ''
    this.$store.commit('newProductDetail/updateIsLogin', isLoginFn())
    this.$nextTick(() => {
      fixIOSScrollIssue()
    })
  },
  destroyed() {
    window.removeEventListener('scroll', GBDetailAnalysisEvent.destroyed())
    hashUnBind({ hashChangeCallback: this.hashChangeCallback })

    appEventCenter.$off('detailPageOnload', this.detailPageOnloadEventCallback)
    appEventCenter.$off('detailFetchMoreInfo', this.detailFetchMoreInfoEventCallback)
    appEventCenter.$off('detailLoginSucCb', this.loginModalSucCb)

    changeDeepLinkElement(this.$route)
    window.vBus && window.vBus.$off('addToBagSuccess', this.addToBagSuccess)
  },
  beforeDestroy() {
    this.initDetailPopStatus() // 弹窗初始化状态更新
  },
  methods: {
    ...mapMutations('productDetail/Description', ['update_detail_o_pos_status', 'update_detail_f_pos_status']),
    ...mapMutations('productDetail/UserManual', ['update_user_manual_status']),
    ...mapMutations('productDetail', ['init_new_product_detail']),
    ...mapMutations(['changeRootStatus', 'changeRootSLoadingStatus']),
    ...mapMutations('newProductDetail/common', [
      'updateExternalSkuCode',
      'updateLoading',
      'updateAttriBute',
      'updateMainAttribute',
      'updateOneClickPayVerifyDoneStatus',
      'updateOneClickPayState',
      'updateDescriptionStatus',
      'updateUserManualStatus',
      'updateShoplookLightbox',
      'updateExposeGoods',
      'updateInitExposeStatus',
      'updateSimilarItemsStatus',
      'updateReportStatus',
      'updateReportStatusOld',
      'updateReportStatusDsa',
      'updateReportFormStatus',
      'updateCodPolicyStatus',
      'updateDetailOPos',
      'updateDetailFPos',
      'updatePerformance',
      'updateCacheScreenData',
      'updateGuaranteeStatus', // TODO @Seb sun
      'updateCustomerShow', // TODO @Jason lai
      'updatedDelayDestroy',
      'updateRecommendGoodsIdMap', // 推荐去重缓存数据
      'updatePlayVimeoStatus'
    ]),
    ...mapMutations('newProductDetail/PromotionEnter', ['updateViewPromotionStatus']),
    // 提供给hash.js调用, 勿删
    ...mapMutations('newProductDetail/SizeGuide', ['updateShowSizeGuideDrawer', 'updateCheckSizeAndSizeGuide']),
    // --end
    ...mapActions('newProductDetail', ['goodsReadyCallBack', 'loginModalSucCb', 'allDataReadyCallBack']),
    // 新型数据结构方法
    
    ...mapMutations('newProductDetail/common', [
      'updateGoodsReady',
      'updateRealTimeReady',
      'updateFromSwitchColor',
      'updateAddToBagForm',
      'updateExternalSizeInfoIndex',
      'updateAddToBagMainGoods'
    ]),
    ...mapMutations('newProductDetail', ['initNewProductDetail', 'initDetailPopStatus', 'clearLastGoodsInfo']),
    ...mapMutations('newProductDetail/CommentPopup', [
      'closeViewCommentPop',
      'openViewCommentPop',
      'updateShowReviewImgbox',
      'updateShowSortList',
      'updateShowColorSizeList',
      'updateShowRatingList',
      'updateDrawerFilterShow'
    ]), // 给 hashChangeCallback 用的
    ...mapActions('newProductDetail/common', ['asyncScroll', 'updateExposeStatus']),
    // NEW BFF
    ...mapActions('productDetail', ['get_product_second_info', 'init_hybrid_modules_with_client', 'fetch_fs_info', 'handle_recent_view']),
    ...mapMutations('productDetail/CommentPopup', [
      'open_view_comment_pop',
      'close_view_comment_pop',
      'update_show_review_imgbox',
      'update_show_sort_list',
      'update_show_color_size_list',
      'update_show_rating_list',
      'update_drawer_filter_show',
    ]),
    ...mapMutations('productDetail/CustomerGallery', ['update_customer_show']),
    ...mapMutations('productDetail/common', ['update_cache_goods_imgs', 'update_second_info_ready', 'update_third_info_ready', 'update_play_vimeo_status', 'update_show_try_on_ar_content']),
    ...mapMutations('productDetail/ShippingEnter', [
      'update_drawer_shipping_main_drawer_open',
      'update_drawer_shipping_guarantee_open',
      'update_drawer_shipping_info_open',
      'update_drawer_return_cod_open',
      'update_drawer_report_open',
      'update_drawer_report_us_open',
      'update_drawer_report_das_open',
      'update_drawer_report_form_status'
    ]),
    ...mapMutations('productDetail/Recommend', ['update_show_outfit_alone']),
    ...mapMutations('productDetail/MainPicture', ['update_show_outfit_ymal']),
    ...mapMutations('productDetail/Middle', ['update_pv_clicked_rank_id', 'update_protect_rank_id']),
    onArboxClose () {
      this.update_show_try_on_ar_content(false)
    },
    onCareBearsStarted () {
      daEventCenter.triggerNotice({ daId: '1-6-1-165' })
    },
    onCareBearsFinished () {
      daEventCenter.triggerNotice({ daId: '1-6-1-166' })
    },
    onMainPictureMounted () {
      this.isMainPictureMounted = true
    },
    initOutfitStatus(){
      if(this.product_detail_abt_info?.newoutfit?.p?.newoutfit == 'addbaginymal'){
        this.update_show_outfit_ymal(false) // 切换pv重置outfit-ymal模块显示
      }
      if(this.product_detail_abt_info?.newoutfit?.p?.newoutfit == 'addbagalone'){
        this.update_show_outfit_alone(false) // 切换pv重置outfit-ymal模块显示
      }
      // 切换色块重置已加车数据
      this.updateAddToBagMainGoods({})
    },
    updateRealTimeData(goodsId, isAfterSsr = false) {
      // 客户端同步加载realTime
      this.$store
        .dispatch('newProductDetail/asyncHotModles', {
          goods_id: goodsId,
          mallCode: this.mallCode,
          isAfterSsr,
        })
    },
    initDetailState(needInit) {
      if (!needInit) return
      const { addToBagForm, externalSizeInfoIndex } = this
      if (addToBagForm?.quantity !== 1 || addToBagForm?.sku_id || Object.keys((externalSizeInfoIndex || {}))?.length) {
        const curStock = this.detail?.mall?.[this.mallCode]?.stock || 0
        const checkQuantity = addToBagForm?.quantity || 1
        let cur_quantity = checkQuantity > curStock ? curStock : checkQuantity
        this.updateAddToBagForm({
          attrs: [],
          goods_id: '',
          quantity: !this.fromSwitchColor || cur_quantity === 0 ? 1 : cur_quantity,
          sku_id: ''
        })
        this.updateExternalSizeInfoIndex({})
      }
    },
    sizeCompAsync(obj = {}) {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.updateExternalSizeInfoIndex(obj)
      }, 300)
    },
    setFeedbackRec(init) {
      const { ymal = [], similar = [] } = this.exposeGoods
      const { goodsId, addBagStatus, addWishStatus } = this.initExposeStatus
      if (init) {
        setFeedbackRecInSession({
          goodsId,
          ymal: '',
          similar: '',
          addWishStatus: 0,
          addBagStatus: 0
        })
        this.updateExposeStatus({ addWishStatus: 0, addBagStatus: 0 })
        // this.updateExposeGoods({
        //   ...this.exposeGoods,
        //   ymal: [],
        //   similar: []
        // })
      } else if (this.exposeGoods.status) {
        // 谨慎添加 会造成转场慢
        setFeedbackRecInSession({
          goodsId,
          ymal: ymal.join(','),
          similar: similar.join(','),
          addWishStatus,
          addBagStatus
        })
      }
    },
    detailPageOnloadEventCallback() {
      this.bindEvent()
      // 切颜色不储存数据
      if (!this.fromSwitchColor) this.handle_recent_view()
      // 页面加载完成后重置fromSwitchColor状态值
      this.updateFromSwitchColor(false)
    },
    detailFetchMoreInfoEventCallback() {
      GBDetailAnalysisEvent.init()
    },
    detailPageScrollToTop() {
      if (!window.isBodyFixed) {
        window.scrollTo(0, 0)
      }
    },
    fid(e) {
      if (e.isTrusted) {
        appEventCenter.$emit('allLazyComponentRender')
      }
    },
    async getDetailInfo() {
      const goods_id = this.$route.params[1]
      const mallCode = this.$route.query?.mallCode
      const status = await this.requestDetailInfo({ goodsId: goods_id, mallCode, realTimeConfig: {
        estimatedPriceInfo: true
      } })
      if (status != 'stop') {
        this.dealOther()
      }
    },
    dealOther() {
      this.getBreadcrumbList()
    },
    async requestDetailInfo({ goodsId, mallCode, realTimeConfig = {} } = {}) {
      // 商品信息获取
      // 巴西线下购商品数据追踪
      this.initSa(goodsId)
      const status = await this.fetch_fs_info({
        goods_id: goodsId,
        mallCode,
        realTimeConfig,
        isSwitchColor: this.fromSwitchColor,
        callback: () => {
          this.reportInit()
        },
        err404: () => {
          if (typeof window === 'undefined') {
            return
          }
          const { langPath = '' } = window.gbCommonInfo || {}
          window.location.replace(`${langPath}/product404.html?from=product`)
        }
      })

      // 快速加车切换颜色后加载一段时间再滚动
      this.asyncScroll()

      this.recordPageView()
      return Promise.resolve(status)
    },
    initSa(goodsId) {
      if (typeof window === 'undefined') {
        return
      }
      const { ici = '' } = this.$route.query
      const { picType } = this.carouse_data
      // 埋点声明
      window.PageGroupOverview = '商品详情页'
      window.PageGroup = '商品详情页'
      const url_from = getQueryString({ key: 'url_from' })
      SaPageInfo = {
        page_name: 'page_goods_detail',
        page_id: 7,
        start_time: Date.now(),
        page_param: {
          goods_id: goodsId,
          // 自有埋点资源位信息
          src_identifier: url_from
            ? `uf=${url_from}`
            : getQueryString({ key: 'src_identifier' }) || '',
          src_module: url_from ? 'ads' : getQueryString({ key: 'src_module' }) || '',
          src_tab_page_id: getQueryString({ key: 'src_tab_page_id' }) || '',
          attribute_list: this.attribute,
          mainattr: this.mainAttribute,
          pic_type: [...picType]?.join(','),
          pagefrom: ici,
          img_view_index: 1,
          gds_img_view_cnt: 1,
          is_switch_skc: getQueryString({ key: 'isFromSwitchColor' }) ? 1 : 0,
        }
      }

      setAnalyisParams()
    },
    async reportInit() {
      const { goods_id: goodsId } = this
      const haveMoreDetail = this.pageComponents.moreDetailConf && this.pageComponents.moreDetailConf.partList && this.pageComponents.moreDetailConf.partList.length
      SaPageInfo.page_param.sellprice = this.price_info.salePrice.usdAmount
      SaPageInfo.page_param.is_include_moredetails = haveMoreDetail ? 1 : 0
      SaPageInfo.page_param.customer_type = ''
      SaPageInfo.page_param.mall_code = this.mallCode
      SaPageInfo.page_param.is_default_mall = this.defaultMallCode == this.mallCode ? 1 : 0
      SaPageInfo.page_param.is_mall_stock = this?.detail?.mall?.[this.mallCode]?.stock > 0 ? 1 : 0
      // SaPageInfo.page_param.local_size = this.hasLocalSize ? 1 : 0 // todo
      const hasLocalSize = this?.size_attr_info?.attr_value_list?.some?.(d => d.attr_value_local_size?.length > 0)
      SaPageInfo.page_param.local_size = hasLocalSize ? 1 : 0 // todo test

      SaPageInfo.page_param.is_attr_fold = this.show_sale_attr_fold_entry ? '1' : '0'
      SaPageInfo.page_param.is_include_video = this.showVideoEntry && (this.show_vimeo_player || this.show_native_player) ?  1 : 0
      // 商详曝光埋点 page_goods_detail 增加页面属性 lowstock_type
      const { is_absolute_low_inventory, is_relatively_low_inventory } = this.detail?.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
      SaPageInfo.page_param.lowstock_type = is_absolute_low_inventory === true ? 1 : is_relatively_low_inventory === true ? 2 : 0
      if (this.recommend_retail_price && !this.recommend_retail_price.skuCode) {
        SaPageInfo.page_param.recommend_retail_price = this.recommend_retail_price?.usdAmountWithSymbol || 0
      }
      if (this.is_some_sku_sold_out || this.is_spu_sold_out) {
        SaPageInfo.page_param.spusoldout = this.is_spu_sold_out ? 1 : 0
      }
      SaPageInfo.page_param.is_show_trend = this.trends_info.show ? '1' : '0'

      let unitPriceInfo = this.unit_price_info
      if (unitPriceInfo) {
        let { pc_price, pc } = unitPriceInfo.analysisData || {}
        SaPageInfo.page_param.pc_price = pc_price || 0
        SaPageInfo.page_param.pc = pc || 0
      }
      refreshAnalyisParams({
        goods_id: goodsId,
        detailAbt: this.fs_abt
      })
      return Promise.resolve()
    },
    setQueryString() {
      const mainAttribute = getQueryString({ key: 'main_attr' }) || ''
      const openCollectRecommend = getQueryString({ key: 'showFeedbackRec' }) == '1'

      if (mainAttribute) {
        this.updateAttriBute('')
        this.updateMainAttribute(mainAttribute)
      } else {
        const attr_ids = getQueryString({ key: 'attr_ids' }) || ''
        this.updateAttriBute(attr_ids)
        this.updateMainAttribute('')
      }

      if (openCollectRecommend) {
        this.updateExposeGoods({
          ...this.exposeGoods,
          status: true
        })
        this.updateInitExposeStatus({
          ...this.initExposeStatus,
          goodsId: this.$route.params[1]
        })
        this.setFeedbackRec(true)
      }
    },
    bindEvent() {
      // cancel hash value
      if (location.hash) {
        history.replaceState(null, null, location.href.replace(location.hash, ''))
      }
    },
    async bfCache() {
      if (typeof window !== 'undefined') {
        let data
        try {
          data = JSON.parse(sessionStorage.getItem('productDetailBfCache'))
          sessionStorage.removeItem('productDetailBfCache')
        } catch (error) {
          /* empty */
        }
        const result = {}
        if (data) {
          const mainImg = data.goods_lazy_img || data.goods_img
          result.bf_first_image = {
            bfCache: mainImg
          }
          const detail_image = data.detail_image?.[0] || {}
          result.bf_second_image = {
            bfCache: detail_image.origin_image || ''
          }
          this.update_cache_goods_imgs(result)
          let performance_open = this.isPerformance
          if (!performance_open) {
            const { IsPerformance } = await getUserAbtData()
            performance_open = IsPerformance?.param?.IsPerformance === 'A'
          }

          if (performance_open) {
            const { screen_data = null } = data
            this.updatePerformance(true)
            screen_data && this.updateCacheScreenData(screen_data)
          } else {
            this.updatePerformance(false)
          }
        } else {
          this.clearImgBfCache()
        }
      }
    },
    clearImgBfCache() {
      
      this.update_cache_goods_imgs({
        bf_first_image: {
          bfCache: '',
        },
        bf_second_image: {
          bfCache: '',
        }
      })
    },
    cacheDetailsImageFormat(data) {
      if (!data || !data.length) {
        return [
          {
            origin_image: ''
          }
        ]
      }

      return data.map(item => {
        return {
          bfCache: item.origin_image,
          origin_image: item.origin_image
        }
      })
    },
    addToBagSuccess() {
      const activeElem = document.activeElement
      const elem = document.querySelector('#add-to-bag-success') || document.createElement('div')
      elem.setAttribute('id', 'add-to-bag-success')
      elem.setAttribute('aria-label', this.language_v2.SHEIN_KEY_PWA_15021)
      elem.setAttribute('tabindex', '0')
      elem.setAttribute('type', 'text')
      elem.setAttribute('role', 'text')
      elem.innerText = this.language_v2.SHEIN_KEY_PWA_15021
      elem.style.cssText = 'position: absolute; left: -10000px; top: -1000px;visibility: hidden'
      const dom = document.querySelector('li[key-one="recommend"]')
      try {
        dom && dom.append(elem)
      } catch (e) {
        dom && dom.appendChild(elem)
      }
      this.$nextTick(() => {
        setTimeout(() => {
          elem.style.top = document.scrollingElement.scrollTop + 100 + 'px'
          elem.focus()
          setTimeout(() => {
            activeElem.focus()
          }, 2000)
        }, 3000)
      })
    },
    // 一键购校验结束
    oneClickPayVerifyDone({ hasQualification, billInfo }) {
      const { oneClickPayState } = this
      this.updateOneClickPayState({
        ...oneClickPayState,
        support: !!hasQualification,
        billInfo: billInfo || null
      })
      this.updateOneClickPayVerifyDoneStatus(true)
    },
    cutImg(imgUrl, designWidth, exp) {
      const locals = this.locals
      const { RESOURCE_SDK = null } = locals
      // const { RESOURCE_SDK = {} } = this
      const {
        deviceData = '',
        isSupportWeb = '',
        isSupprotCut = false,
        sceneMap = {}
      } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp
      }
      return transfromAndCutImg(cutData)
    },
    // 记录用户24小时内访问商详的次数
    recordPageView() {
      if (typeof window == 'undefined') return
      const pageViewCookieKey = 'goods_detail_page_view'
      const expire = 1000 * 60 * 60 * 24
      let cacheValue = JSON.parse(localStorage?.getItem(pageViewCookieKey))

      if(cacheValue && cacheValue.end && cacheValue.end > Date.now()){
        // 24小时内访问记录上限
        if(cacheValue.value >= 1000) return
        // 如果有值，并且在有效期范围，则继续累加
        let count = cacheValue.value + 1
        const storageData = JSON.stringify({ value: count, end: cacheValue.end  })
        localStorage?.setItem(pageViewCookieKey, storageData)
      }else{
        // 初次进入或者超过有效期，则重置为1
        const storageData = JSON.stringify({ value: 1, end: Date.now() + expire  })
        localStorage?.setItem(pageViewCookieKey, storageData)
      }
    }
  }
}
</script>

<style lang="less">
.detail-private {
  /deep/ .recommend-v2__tab-item {
    padding: 0.16rem 0.64rem 0.32rem 0;
  }
}
.mgds-goodsd-bg {
  background-color: #f6f6f6;
}

.detail-page__loading {
  .S-loading__cur-page-mask {
    background-color: transparent !important; /* stylelint-disable-line */
  }
}

.main-placeholder {
  background-color: @sui_color_white;
  padding: 0 .32rem;
}
.main-pic__container {
  background-color: @sui_color_white;
  padding: 0 .32rem;
  position: relative;
  .goods-detail-top__arContent {
    z-index: @zindex-modal-mask;
    position: absolute;
    top: 0;
    left: -0.32rem;
    width: calc(100% + 0.64rem);
    height: 100%;
  }
}



</style>
